<template>
  <div class="content-card w-full shadow-lg">
    <div class="card-header card-header-flex flex justify-between items-center px-2 rounded-t-lg bg-brand h-12">
      <p>
        <!-- <b
          ><span v-if="minimalView">{{ getWeekdayForDate(data.date, false) }}, </span><span v-else>{{ getWeekdayForDate(data.date, true) }}, </span>{{ getFormatedDate(data.date) }}</b
        > -->
        <b>Montag, den 01.01.2022</b>
      </p>
      <div class="flex gap-3">
        <button><font-awesome-icon icon="edit" class="icon" /></button>
        <button><font-awesome-icon icon="trash" class="icon" /></button>
      </div>
    </div>
    <div class="card-content rounded-b-lg bg-card-content px-2 pb-2">

      <div class="card-content-info space-y-3" >
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi nihil dolores aliquam? Mollitia accusantium inventore, modi voluptate nobis impedit placeat vero maxime minima eius dolores labore. Consectetur temporibus commodi fugiat!</p>
        
        <p><font-awesome-icon icon="star-half-alt" class="icon"/> Du hast diesen Dienst positiv bewertet.</p>

        <p><font-awesome-icon icon="exchange-alt" class="icon"/> Du hast diesen Dienst getauscht.</p>

        <div class="">
          <p><b>Zugverband</b></p>
          <p>1001 + 1002</p>
        </div>

         <a class="link" href="#">Dienst ansehen</a>
      </div>

      <!-- <div class="bg-lines bg-cover bg-center w-full rounded-md p-4 mb-3">
        <div class="content-card-container card-one-center sm:max-w-sm max-w-full mx-auto">
          <ShiftBox :data="shift" :minimalView="true"></ShiftBox>
        </div>
      </div> -->

<!--
      <ShiftDetails :data="data" :class="align"></ShiftDetails>

      <ShiftWorkingTime :data="data" v-if="data.typ == 1"></ShiftWorkingTime>
-->
     
      
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ShiftBox from "@/components/ShiftBox";


export default {
  name: "LogBox",
  components: {
    ShiftBox
  },
  data() {
    return {
      shift: {
              "id": "988b0545-1a91-418d-bc43-65495ce13aae",
              "date_created": "2022-01-03T15:20:36.229Z",
              "user_date_unique": "dba9da68-80e4-49fc-b54a-cdda78dfc234_2022-01-24",
              "date": "2022-01-24T00:00:00",
              "header_break": -1,
              "header_nr": "7508",
              "header_service": 1,
              "shift_paidtime": "08:19",
              "shift_workingtime": "08:49",
              "time_start": "16:08",
              "time_end": "00:57+",
              "typ": 1,
              "url": "https://connect.kvb-koeln.de/mbweb/main/ivu/desktop/,DanaInfo=fahrerinfo,Port=20000+duty-details?beginDate=2022-01-24&allocatedEmployeeId=727458",
              "header_date": "2022-01-24T00:00:00",
              "hash": null,
              "date_updated": "2022-01-27T01:24:25.656Z",
              "shift": [
                  {
                      "id": "3abf1aca-71a6-4395-9cb5-530159c52531",
                      "start_location": "NEU",
                      "end_location": "FSP",
                      "sort": 0,
                      "start_time": "16:08",
                      "end_time": "17:53",
                      "start_direction": "SLB",
                      "end_direction": "SLB",
                      "date_created": "2022-01-27T01:24:25.932Z",
                      "date_updated": null,
                      "line": "4",
                      "cycle": "64",
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 0
                  },
                  {
                      "id": "422231db-dd1f-4d50-a42e-4e1e073a3ea3",
                      "start_location": "FSP",
                      "end_location": "NEU",
                      "sort": 1,
                      "start_time": "18:24",
                      "end_time": "20:20",
                      "start_direction": "MEN",
                      "end_direction": "MEN",
                      "date_created": "2022-01-27T01:24:25.938Z",
                      "date_updated": null,
                      "line": "3",
                      "cycle": "63",
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 0
                  },
                  {
                      "id": "daba2003-31fe-4308-a78c-3ed60f089826",
                      "start_location": "S - NEUM",
                      "end_location": "S - NEUM",
                      "sort": 2,
                      "start_time": "20:26",
                      "end_time": "20:56",
                      "start_direction": null,
                      "end_direction": null,
                      "date_created": "2022-01-27T01:24:25.948Z",
                      "date_updated": null,
                      "line": null,
                      "cycle": null,
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 1
                  },
                  {
                      "id": "52b51702-5cf1-4136-8dc1-d275ffd58992",
                      "start_location": "NEU",
                      "end_location": "MRH-S",
                      "sort": 3,
                      "start_time": "21:00",
                      "end_time": "00:51",
                      "start_direction": "VRS",
                      "end_direction": "",
                      "date_created": "2022-01-27T01:24:25.960Z",
                      "date_updated": null,
                      "line": "3",
                      "cycle": "61",
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 0
                  }
              ],
              "exchange": [
                  "9912b572-6bfd-4dcc-918a-e2c12e875ad2"
              ],
              "ml_shifts": [],
              "user": {
                  "id": "dba9da68-80e4-49fc-b54a-cdda78dfc234",
                  "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                  "verified": true,
                  "blocked": false,
                  "confirmed": true,
                  "servicecard_id": "123456789",
                  "last_access": "2022-02-01T23:13:33.562Z",
                  "status": "active",
                  "confirmed_at": "2021-10-12T01:32:36.353Z",
                  "verified_at": "2022-01-01T22:05:45.662Z",
                  "first_name": "Tobias",
                  "last_name": "Test Scheler",
                  "last_page": "/content/shifts/988b0545-1a91-418d-bc43-65495ce13aae",
                  "email": "tobias.scheler@me.com",
                  "password": "**********",
                  "location": null,
                  "title": null,
                  "description": null,
                  "tags": null,
                  "avatar": null,
                  "language": "en-US",
                  "theme": "auto",
                  "tfa_secret": null,
                  "role": "ac869b88-3e03-4edf-8539-16ff67f44355",
                  "verification": [
                      "dba9da68-80e4-49fc-b54a-cdda78dfc234"
                  ]
              }
      }
    }
  },
  props: {

  },
  methods: {
    
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getWeekdayForDate",
      "getShiftTimelineForDate",
      "getFormatedDate",
      "getServiceSituationIcon",
    ]),
  },
};
</script>