<template>
  <!-- <div class="flex flex-wrap items-center justify-center gap-x-8 gap-y-6"> -->
  <div class="py-3">
  <p> 
    <b><u>Arbeitszeit:</u></b> {{data.shift_workingtime}}h<br>
    {{data.time_start}} - {{data.time_end}} Uhr
  </p>  
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ShiftWorkingTime",
  data() {
    return {};
  },
  props: {
    data: null,
  },
  methods: {},
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([]),
  },
};
</script>