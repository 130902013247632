<template>
  <div class="content-card w-full shadow-lg" v-if="isVisible()">
    <div class="card-header card-header-flex flex justify-between items-center px-2 rounded-t-lg bg-brand h-12" v-bind:class="classObject()">
      <p>
        <b
          ><span v-if="minimalView">{{ getWeekdayForDate(data.date, false) }}, </span><span v-else>{{ getWeekdayForDate(data.date, true) }}, </span>{{ getFormatedDate(data.date) }}</b
        >
      </p>
      <!-- ServiceSituationIcon -->
      <ServiceSituationIcon :data="data"></ServiceSituationIcon>
    </div>
    <div class="card-content rounded-b-lg bg-card-content px-2 pb-2">
      <div
        class="card-content-info"
        v-if="data.shift.length == 0 && data.typ == 0"
      >
        <font-awesome-icon icon="question" class="icon brand-color-dark" />
        <p><b>Keine Daten vorhanden</b></p>
        <p>
          <a class="link" href="https://app.stadtbahnfahrer.club/syncmanager" target="_newTab">Synchronisiere</a> diesen Dienst, um ihn hier zu
          sehen, Benachrichtigungen dazu zu erhalten und ihn zur Statistik
          hinzuzufügen.
        </p>
      </div>

      <div class="card-content-info" v-if="data.typ == 4">
        <font-awesome-icon
          icon="umbrella-beach"
          class="icon brand-color-dark"
        />
        <p><b>Urlaub</b></p>
      </div>

      <div class="card-content-info" v-if="data.typ == 2">
        <font-awesome-icon icon="circle" class="icon brand-color-dark" />
        <p><b>Frei</b></p>
      </div>

      <div class="card-content-info" v-if="data.typ == 3">
        <font-awesome-icon icon="circle" class="icon brand-color-dark" />
        <p><b>Z-Frei</b></p>
      </div>

      <div class="card-content-info" v-if="data.typ == 5">
        <font-awesome-icon icon="virus" class="icon brand-color-dark" />
        <p><b>Krank</b></p>
      </div>

      <div class="card-content-info" v-if="data.typ == 6">
        <!-- <font-awesome-icon icon="virus" class="icon brand-color-dark" /> -->
        <p><b>Frei am Feiertag</b></p>
      </div>

      <div class="card-content-info" v-if="data.typ == 7">
        <!-- <font-awesome-icon icon="virus" class="icon brand-color-dark" /> -->
        <p><b>MDT</b></p>
      </div>

      <div class="card-content-info" v-if="data.typ == 8">
        <!-- <font-awesome-icon icon="virus" class="icon brand-color-dark" /> -->
        <p><b>US</b></p>
      </div>

      <div class="card-content-info" v-if="data.typ == 9">
        <!-- <font-awesome-icon icon="virus" class="icon brand-color-dark" /> -->
        <p><b>RUF</b></p>
      </div>

      <div class="card-content-info" v-if="data.typ == 10">
        <!-- <font-awesome-icon icon="virus" class="icon brand-color-dark" /> -->
        <p><b>BÄD</b></p>
      </div>

      <ShiftDetails :data="data" :class="align"></ShiftDetails>

      <ShiftWorkingTime :data="data" v-if="data.typ == 1"></ShiftWorkingTime>

      <div class="card-footer py-2" v-if="data.typ == 1 && timeline == 0 && !minimalView">
        <hr class="mt-2 mb-4">
        <div class="flex flex-wrap gap-1 items-center">
        <button class="card-button cursor-pointer text-sm" @click="openModalRating()" v-if="data.ml_shifts.length == 0" v-show="data.exchange.length == 0"
          ><font-awesome-icon icon="star-half-alt" class="icon"
        /> Bewerten</button>
        <!-- <button class="card-button cursor-pointer text-sm" @click="openExchangeModal()" 
          ><font-awesome-icon icon="exchange-alt" class="icon"
        /> Tauschen</button> -->
        <!-- <div v-else><small><font-awesome-icon icon="star-half-alt" class="icon"/> Du hast diesen Dienst <span v-show="data.ml_shifts[0].rating">positiv</span><span v-show="!data.ml_shifts[0].rating">negativ</span> bewertet.</small></div> -->
        

        <!-- <button class="card-button cursor-pointer text-sm" @click="openModalDiary()"
          ><font-awesome-icon icon="edit" class="icon"
        /> Lokbuch</button> -->
        

        <div v-if="data.exchange.length > 0" class="w-full"><small><font-awesome-icon icon="exchange-alt" class="icon"/> In der <router-link :to="{path: '/tausch/' + data.exchange[0].id}" class="link">Tauschbörse</router-link></small></div>
        </div>
      </div>

      <div class="card-footer py-2" v-if="data.typ == 1 && timeline > 0 && !minimalView">
        <hr class="mt-2 mb-4">
        <div v-if="data.exchange_complete">
          <font-awesome-icon icon="exchange-alt" class="icon"/> <router-link :to="{path: '/tausch/' + data.exchange_complete.id}" class="link">Getauscht</router-link>
        </div>
        <button class="card-button cursor-pointer text-sm" @click="openExchangeModal()" v-else-if="data.exchange.length == 0"
          ><font-awesome-icon icon="exchange-alt" class="icon"
        /> Tauschen</button>
        <div v-else>
          <small>
            <font-awesome-icon icon="exchange-alt" class="icon"/> In der <router-link v-if="data.exchange[0].id" :to="{path: '/tausch/' + data.exchange[0].id}" class="link">Tauschbörse</router-link><span v-else>Tauschbörse</span>
            <span v-if="data.exchange[0].exchange_submissions && data.exchange[0].exchange_submissions.length > 0" class="text-yellow-500"><br><b>{{data.exchange[0].exchange_submissions.length}}</b> offene Anfrage(n)</span>
          </small>
        </div>
        <div v-if="data.exchange_submissions.length > 0"><small><font-awesome-icon icon="exchange-alt" class="icon"/> In <router-link :to="{path: '/tauschboerse/angebotene-dienste'}" class="link">angebotene Dienste</router-link> in der Tauschbörse</small></div>
        <!-- <a class="card-button"><font-awesome-icon icon="car" class="icon" /></a> -->
      </div>
      
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ServiceSituationIcon from "@/components/ServiceSituationIcon";
import ShiftDetails from "@/components/ShiftDetail";
import ShiftWorkingTime from "@/components/ShiftWorkingTime";

export default {
  name: "ShiftBox",
  components: {
    ServiceSituationIcon,
    ShiftDetails,
    ShiftWorkingTime
  },
  data() {
    return {
      timeline: 1,
    };
  },
  props: {
    t: 0,
    determination: false,
    data: null,
    hidePastShifts: false,
    minimalView: false,
    align: String
  },
  methods: {
    classObject: function () {
      if(this.minimalView) {
        return false
      }
      this.timeline = this.getShiftTimelineForDate(this.data.date);
      return {
        "shadow-future": this.timeline == 1,
        "shadow-today": this.timeline == 2,
        "shadow-past": this.timeline == 0,
      };
    },
    isVisible() {

      if(this.data == null) {
        return false
      }

      const date = new Date(this.data.date);

      if (
        !this.hidePastShifts ||
        (this.hidePastShifts &&
          date.setDate(date.getDate() + 1) >=
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            ))
      ) {
        return true;
      }

      return false;
    },
    openExchangeModal(){
      this.$emit('openExchangeModal',this.data);
    },
    openModalRating(){
      this.$emit('openModalRating',this.data);
    },
    openModalDiary(){
      this.$emit('openModalDiary',this.data);
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getWeekdayForDate",
      "getShiftTimelineForDate",
      "getFormatedDate",
      "getServiceSituationIcon",
    ]),
  },
};
</script>