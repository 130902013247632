<template>
  <div id="Statistic" v-if="!isLoading">
    <!-- <div>
      <h1 class="brand-color-dark">Statistik</h1>
    </div> -->

    <div class="lg:sticky lg:top-16 relative top-0 border-b-4 border-blue-700 rounded-md grid grid-cols-1 sm:grid-cols-2 gap-2 justify-between items-center px-3 py-3 bg-gray-800 my-3">
      <div><h1 class="brand-color-dark text-center sm:text-left">Lokbuch</h1><p class="text-center sm:text-left">Dein persönliches Dienst-Tagebuch</p></div>
      <div class=""><button class="w-full text-center sm:text-right"><font-awesome-icon icon="plus" class="icon" /> Neuen Eintrag</button></div>
    </div>

    <!-- <div class="inline-block lg:hidden border-2 border-blue-700 fixed bottom-3 rounded-md px-3 py-3 bg-gray-800 ">
      <div class="flex items-center justify-between gap-2"><button @click="calendarLeft"><font-awesome-icon icon="chevron-left" class="icon" /></button><button  @click="calendarToday" class="text-lg">{{getMonthLabel(shiftViewerCalendar.month)}} {{shiftViewerCalendar.year}}</button><button @click="calendarRight"><font-awesome-icon icon="chevron-right" class="icon" /></button></div>
    </div> -->

    <div class="space-y-3">
      <LogBox />
      <LogBox />
      <LogBox />
      <LogBox />
      <LogBox />
    </div>

    
  </div>
</template>




<script>
import LogBox from "@/components/LogBox";

import { mapGetters } from "vuex";

export default {
  name: "Log",
  components: {
    LogBox
  },
  props: {},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      user: "getUser",
      shifts: "getShifts",
      getFormatedDate: "getFormatedDate",
      getWeekdayForDate: "getWeekdayForDate",
      shiftViewerCalendar: "getShiftViewerCalendar",
      getMonthLabel: "getMonthLabel"
    }),
  },
};
</script>

<style scoped>
  .flexbox {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-4 py-3;
  }

  .box {
    @apply rounded-lg sm:p-6 p-3;
  }

  .chart {
    @apply mx-auto py-5 max-h-96;
  }
</style>